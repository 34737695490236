/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDhuFnHpjy5rxwx_plY1uUsOHD55sQ5H5c",
  "appId": "1:633420315723:web:3ebd90703baa6b0a14e1ce",
  "authDomain": "schooldog-i-paulding-ga.firebaseapp.com",
  "measurementId": "G-9X1RKXDF8X",
  "messagingSenderId": "633420315723",
  "project": "schooldog-i-paulding-ga",
  "projectId": "schooldog-i-paulding-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-paulding-ga.appspot.com"
}
